<template>
  <div class="updateAndroid">
    <el-button type="text" @click="$router.go(-1)">&lt;&lt;返回</el-button>
    <!--主体内容-->
    <div class="content">
      <TitleModule :title="title"></TitleModule>
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <div class="disabledAddListWrapper">
          <el-table :data="formInline.channelList" style="width: 100%">
            <el-table-column label="上传安装包" align="center" width="320">
              <template slot-scope="scope">
                <el-upload
                  v-if="!scope.row.downloadUrl"
                  class="upload"
                  :disabled="!isEdit"
                  :ref="'upload' + scope.$index"
                  action="/acb/2.0/application/version/upload"
                  :headers="uploadHeader"
                  accept="apk"
                  name="file"
                  :data="{ applicationId: $route.query.applicationId }"
                  enctype="multipart/form-data"
                  :on-change="fileChange"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-progress="onError"
                >
                  <el-button
                    size="small"
                    type="text"
                    :loading="uploadIndex === scope.$index && tagUp == 1"
                    @click="onUpLoad($event, scope.$index)"
                    :disabled="!isEdit"
                  >
                    上传安装包 <br />(支持APK文件，最大不超过200MB)
                  </el-button>
                </el-upload>
                <div class="uploadText" v-show="scope.row.downloadUrl">
                  <label>包名：</label>
                  <span>{{ scope.row.packageName }}</span>
                  <label>大小：</label>
                  <span>{{ scope.row.fileSize }}</span
                  ><br />
                  <label>版本号：</label>
                  <span>{{ scope.row.versionName }}</span>
                  <label>Versioncode：</label>
                  <span>{{ scope.row.versionCode }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="渠道名称" align="center" width="180">
              <template slot-scope="scope">
                <span
                  ><input
                    type="text"
                    :disabled="formInline.upgradeStatus === 1 || !isEdit"
                    v-model="scope.row.channelName"
                    style="width: 140px"
                    maxlength="100"
                /></span>
              </template>
            </el-table-column>
            <el-table-column label="下载地址" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.downloadUrl || "" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-upload
                  v-if="scope.row.downloadUrl"
                  :disabled="!isEdit"
                  :ref="'upload' + scope.$index"
                  action="/acb/2.0/application/version/upload"
                  :headers="uploadHeader"
                  accept="apk"
                  name="file"
                  :data="{ applicationId: $route.query.applicationId }"
                  enctype="multipart/form-data"
                  :on-change="fileChange"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-progress="onError2"
                >
                  <el-button
                    size="small"
                    type="text"
                    :loading="uploadIndex === scope.$index && tagUp2 == 1"
                    @click="onUpLoad($event, scope.$index)"
                    :disabled="formInline.upgradeStatus === 1 || !isEdit"
                  >
                    重新上传
                  </el-button>
                </el-upload>
                <span></span>
                <el-button
                  :loading="removeIndex === scope.$index"
                  type="text"
                  :disabled="formInline.upgradeStatus === 1 || !isEdit"
                  @click.native.prevent="deleteRow(scope.$index)"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button
            type="success"
            @click.native.prevent="addRow"
            style="margin-top: 10px"
            :disabled="formInline.upgradeStatus === 1 || !isEdit"
            >继续{{ $t('button.addto') }}</el-button
          >
        </div>
        <el-form
          label-position="right"
          :disabled="!isEdit"
          label-width="120px"
          :model="formInline"
          style="width: 600px"
          :rules="rules"
          ref="form"
        >
          <h2>基础配置</h2>
          <el-form-item label="升级方式" prop="updateType">
            <el-radio v-model="formInline.updateType" :label="2">推荐升级</el-radio>
            <el-radio v-model="formInline.updateType" :label="1">强制升级</el-radio>
            <el-radio v-model="formInline.updateType" :label="3">手动升级</el-radio>
          </el-form-item>
          <el-form-item label="更新说明" prop="updateDesc">
            <el-input
              type="textarea"
              placeholder="请输入更新说明"
              maxlength="200"
              :autosize="{ minRows: 4, maxRows: 8 }"
              v-model="formInline.updateDesc"
            ></el-input>
          </el-form-item>
          <h2>高级配置</h2>
          <el-form-item label="升级时间" prop="upgradeType">
            <el-radio
              v-model="formInline.upgradeType"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              :label="1"
              >立即升级</el-radio
            >
            <el-radio
              v-model="formInline.upgradeType"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              :label="2"
              style="margin-right: 20px"
              >定时升级</el-radio
            >
            <el-date-picker
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              v-show="formInline.upgradeType !== 1"
              :clearable="false"
              :editable="false"
              :picker-options="pickerOptions"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="formInline.upgradeTime"
              type="datetime"
              @change="upgradeTimeSelect"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="submitData" style="width: 88px" v-if="isEdit"
            >{{ $t('button.preservation') }}</el-button
          >
          <el-button type="info" @click="$router.go(-1)" style="width: 88px">{{
            isEdit ? "取消" : "返回"
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// noinspection NpmUsedModulesInstalled
import TitleModule from "@/components/titleModule/titleModule";
import formatDate from "format-datetime";
export default {
  name: "updateAndroid",
  data() {
    return {
      tagUp2: "",
      tagUp: "",
      removeIndex: "",
      uploadShow: false,
      title: "添加Android新版本",
      uploadIndex: null,
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      isEdit: "",
      formInline: {
        upgradeStatus: 0,
        updateType: 2,
        applicationName: "",
        productId: "",
        appStoreUrl: "",
        versionName: "",
        updateDesc: "",
        upgradeType: 1,
        upgradeTime: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
        channelList: [
          {
            downloadUrl: "",
            channelName: "",
          },
        ],
      },
      rules: {
        upgradeType: [{ required: true, message: "请选择升级时间", trigger: "change" }],
        updateType: [
          {
            required: true,
            message: "请选择升级方式",
            trigger: "change",
          },
        ],
        updateDesc: [{ required: true, message: "请输入更新说明", trigger: "blur" }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  methods: {
    onUpLoad(event, index) {
      console.log("index", index);
      this.uploadIndex = index;
    },
    addRow() {
      if (!this.formInline.channelList) this.formInline.channelList = [];
      this.formInline.channelList.push({
        channelName: "",
        downloadUrl: "",
      });
    },
    deleteRow(index) {
      if (this.formInline.channelList.length == 1) {
        this.$message.error("安装包必须保留一个不能删除");
        return;
      }
      let removeUrl = this.formInline.channelList[index].downloadUrl;
      this.formInline.channelList.splice(index, 1);
      if (!removeUrl) return;
      this.removeIndex = index;
      this.removePack(removeUrl);
    },
    removePack(removeUrl) {
      this.$axios
        .post("/acb/2.0/application/version/remove", {
          data: {
            downloadUrl: removeUrl,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            // this.$message.success('删除成功');
            this.removeIndex = "";
          }
        })
        .catch(() => {
          // this.$message.error('删除失败');
          this.removeIndex = "";
        });
    },
    queryDetail(applicationId, recordId) {
      this.$axios
        .get("/acb/2.0/application/record/queryDetail", {
          data: {
            applicationId,
            recordId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            let newDetail = res.value;
            for (let key in newDetail) {
              // eslint-disable-next-line no-prototype-builtins
              if (newDetail.hasOwnProperty(key) && newDetail[key]) {
                this.formInline[key] = newDetail[key];
              }
            }
          }
        });
    },
    upgradeTimeSelect(value) {
      let now = new Date().getTime();
      let setTime = new Date(value).getTime();
      if (setTime < now) {
        this.$message.closeAll();
        this.$message.error("选择时间必须大于当前时间");
        return false;
      } else {
        return true;
      }
    },
    channelListVali() {
      let channelListVali = false;
      if (!this.formInline.channelList || this.formInline.channelList.length === 0) {
        channelListVali = true;
      } else if (this.formInline.channelList.length > 0) {
        this.formInline.channelList.forEach((v) => {
          if (!v.channelName) {
            channelListVali = true;
            this.$message.closeAll();
            this.$message.error("请填写渠道信息！");
          }
          if (!v.downloadUrl) {
            channelListVali = true;
            this.$message.closeAll();
            this.$message.error("请上传安卓包！");
          }
        });
      }
      return channelListVali;
    },
    submitData() {
      if (this.channelListVali()) {
        return false;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (
            this.formInline.upgradeType === 2 &&
            this.formInline.upgradeStatus !== 1 &&
            !this.upgradeTimeSelect(this.formInline.upgradeTime)
          ) {
            return false;
          }
          this.$confirm("您确定要保存此操作吗？").then((res) => {
            if (res === "confirm") {
              let url =
                this.isEdit === "add"
                  ? "/acb/2.0/application/record/add"
                  : "/acb/2.0/application/record/update";
              let methods = this.isEdit === "add" ? "post" : "post";
              this.$axios[methods](
                url,
                {
                  data: {
                    applicationId: this.applicationId,
                    updateType: this.formInline.updateType,
                    updateDesc: this.formInline.updateDesc,
                    upgradeType: this.formInline.upgradeType,
                    upgradeTime: this.formInline.upgradeTime,
                    recordId: this.$route.query.recordId,
                    channelList: this.formInline.channelList,
                  },
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              ).then((res) => {
                if (res.state === 0) {
                  this.$message.success("操作成功");
                  // this.$router.push("/appmanager");
                  // this.$router.replace('/appmanager')
                  this.$router.go(-1);
                } else {
                  this.$alert(res.desc);
                }
              });
            }
          });
        }
      });
    },
    beforeAvatarUpload(file) {
      let arr = file.name.split(".");
      let fileName = arr[arr.length - 1];
      const isLt200M = file.size / 1024 / 1024 < 200;
      if (fileName !== "apk") {
        this.uploadIndex = null;
        this.$message.error({
          message: "上传文件只能apk 格式!",
          duration: 5000,
        });
        return false;
      } else if (!isLt200M) {
        this.uploadIndex = null;
        this.$message.error("上传文件大小不能超过 200MB!");
        return false;
      } else {
        return true;
      }
    },
    // 上传文件
    handleAvatarSuccess(response) {
      if (response.state === 0) {
        this.$message({
          message: "上传成功！",
          type: "success",
        });
        this.formInline.channelList[this.uploadIndex] = response.value;
      } else if (response.state === 2 || response.state === 5) {
        if (sessionStorage.toLogin === "true") {
          this.$alert("登录失效，请重新登录", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
            showCancelButton: false,
          })
            .then(() => {
              sessionStorage.toLogin = false;
              this.$router.push("/");
            })
            .catch(() => {
              this.$router.push("/");
            });
        }
      } else {
        this.$alert(response.desc);
      }
      this.uploadIndex = null;
    },
    onError() {
      this.tagUp = 1;
    },
    onError2() {
      this.tagUp2 = 1;
    },
    fileChange() {
      // {{scope.$index}} ->{{uploadIndex}}
      console.log("fileChange");
      this.$nextTick(() => {
        if (typeof this.uploadIndex !== "number") return false;
        this.$refs["upload" + this.uploadIndex].submit();
      });
    },
    // 更新新版时候，查询上一次的详情
    getInfo() {
      this.$axios
        .get("/acb/2.0/application/record/queryLastInfo", {
          data: {
            applicationId: this.$route.query.applicationId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            let newDetail = res.value;
            for (let key in newDetail) {
              // eslint-disable-next-line no-prototype-builtins
              if (newDetail.hasOwnProperty(key) && newDetail[key]) {
                this.formInline[key] = newDetail[key];
              }
            }
          }
        });
    },
  },
  components: {
    TitleModule,
  },
  created() {
    if (this.$route.query.applicationId) {
      this.applicationId = this.$route.query.applicationId;
      this.isEdit = this.$route.query.isEdit;
      if (this.isEdit === "add") {
        this.title = "添加Android新版本";
        this.uploadShow = true;
        // this.getInfo();
      } else {
        if (this.isEdit) {
          this.title = "更新Android新版本";
        } else {
          this.title = "查看详情";
        }
        this.queryDetail(this.$route.query.applicationId, this.$route.query.recordId);
      }
    }
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
  width: 1100px;
}

.disabledAddListWrapper {
  position: relative;
}

.searchWrapper h2 {
  line-height: 50px;
  font-size: 14px;
}
.searchWrapper .upload {
  height: 80px;
  border: 1px dashed #20a0ff;
  border-radius: 4px;
}

/*noinspection CssUnusedSymbol*/
.searchWrapper .upload >>> .el-upload {
  display: block;
  width: 100%;
  height: 100%;
}
.searchWrapper .upload button {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  line-height: 1.5;
}
.uploadText span {
  font-size: 12px;
  margin-right: 10px;
}
.uploadText label {
  font-size: 14px;
}
</style>
